<template>
  <LoadingLayer :get-ready="getReady" />
  <div class="container mx-auto max-w-xs md:max-w-6xl">
    <div class="flex flex-col h-screen md:flex-row md:h-auto">
      <div class="md:basis-1/2 z-10">
        <h1 class="text-4xl font-black mt-10 md:mt-0">No need for more...</h1>
        <p class="text-xl mt-3">
          You only need your mobile device to enter. 
        </p>
        <p class="font-light">Download it and access it easily without using a key during your visit.</p>

        <div class="flex flex-row items-start justify-between gap-8 my-10">
          <div class="flex flex-col gap-3">
            <a v-for="(market, n) in markets" :key="n" :href="market.link">
              <img :src="market.badge" class="h-12" />
            </a>
          </div>
          <div class="w-48 h-48 contain bg-no-repeat bg-right-bottom qrcode-wrapper hidden md:flex">
            <Qrcode class="h-32 rounded-2xl border-8 border-blue-500 shadow-xl shadow-slate-600/50" />
          </div>
        </div>
      </div>

      <div class="md:basis-1/2">
        <Mockups :ios="!isMobile || osName == 'ios'" :android="!isMobile || osName == 'android'" />
      </div>

    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

import LoadingLayer from '@/components/loading-layer.vue';
import Mockups from "@/components/mockups.vue";

import badgeAppStore from "@/assets/badge/app-store.svg";
import badgePlayStore from "@/assets/badge/play-store.svg";
import badgeAppGallery from "@/assets/badge/appgallery.svg";
import DeviceDetector from "device-detector-js";
import Qrcode from './components/qrcode.vue';

const getReady = ref(false);
const hotelsuite = [
  {
    osName: "ios",
    link: "https://apps.apple.com/app/future-hotel-lock/id6447947546",
    badge: badgeAppStore
  },
  {
    osName: "android",
    link: "https://play.google.com/store/apps/details?id=com.kalekilit.smarthotel",
    badge: badgePlayStore
  },
  {
    osName: "huawei",
    link: "https://appgallery.huawei.com/app/C110224895",
    badge: badgeAppGallery
  },
];

const kTech = [
  {
    osName: "ios",
    link: "https://apps.apple.com/tr/app/kale-mobil/id1621835883",
    badge: badgeAppStore
  },
  {
    osName: "android",
    link: "https://play.google.com/store/apps/details?id=com.kalekilit.smartlock",
    badge: badgePlayStore
  },
  {
    osName: "huawei",
    link: "https://appgallery.huawei.com/app/C107003195",
    badge: badgeAppGallery
  },
];

const _id = computed(()=> {
  if(!window?.location?.pathname || !window?.location?.host) return "";
  else if(window.location.host.split('.')[0] === "k-tech") return "k-tech";
  else return window.location.pathname.replace(/[^0-F]/ig, "");
})

const sources = computed(()=> {
  switch(_id.value) {
    case "k-tech": return kTech;
    default: return hotelsuite;
  }
})

const info = ref({});

const isMobile = computed(() => info.value?.device?.type == "smartphone");
const osName = computed(() => info.value?.os?.name?.toLowerCase());
const markets = computed(() => {
  return sources.value.filter((m) => m.link && !isMobile.value || m.osName == osName.value);
})

onMounted(() => {
  const detector = new DeviceDetector();

  info.value = detector.parse(navigator.userAgent);

  if (_id.value && isMobile.value) {
    markets.value.forEach((m) => {
      if (m.osName == osName.value && m.link) {
        setTimeout(() => {
          window.close();
        }, 3000);

        window.location.href = m.link;
      }
    })
  }

  setTimeout(() => getReady.value = true, 1 * 1000);
})
</script>

<style lang="postcss">
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#app {
  @apply flex md:flex-row md:items-center md:justify-center bg-slate-200 min-w-full bg-cover bg-center overflow-hidden;
  background-image: url(@/assets/background.jpg);
  height: 100dvh;
}

.qrcode-wrapper {
  background-image: url(@/assets/visuals/scan-me.svg);
}
</style>